@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Thin.eot');
    src: local('Product Sans Thin'), local('ProductSans-Thin'),
        url('ProductSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Thin.woff2') format('woff2'),
        url('ProductSans-Thin.woff') format('woff'),
        url('ProductSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-MediumItalic.eot');
    src: local('Product Sans Medium Italic'), local('ProductSans-MediumItalic'),
        url('ProductSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-MediumItalic.woff2') format('woff2'),
        url('ProductSans-MediumItalic.woff') format('woff'),
        url('ProductSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-BoldItalic.eot');
    src: local('Product Sans Bold Italic'), local('ProductSans-BoldItalic'),
        url('ProductSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-BoldItalic.woff2') format('woff2'),
        url('ProductSans-BoldItalic.woff') format('woff'),
        url('ProductSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Black.eot');
    src: local('Product Sans Black'), local('ProductSans-Black'),
        url('ProductSans-Black.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Black.woff2') format('woff2'),
        url('ProductSans-Black.woff') format('woff'),
        url('ProductSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-LightItalic.eot');
    src: local('Product Sans Light Italic'), local('ProductSans-LightItalic'),
        url('ProductSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-LightItalic.woff2') format('woff2'),
        url('ProductSans-LightItalic.woff') format('woff'),
        url('ProductSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Light.eot');
    src: local('Product Sans Light'), local('ProductSans-Light'),
        url('ProductSans-Light.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Light.woff2') format('woff2'),
        url('ProductSans-Light.woff') format('woff'),
        url('ProductSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Italic.eot');
    src: local('Product Sans Italic'), local('ProductSans-Italic'),
        url('ProductSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Italic.woff2') format('woff2'),
        url('ProductSans-Italic.woff') format('woff'),
        url('ProductSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Bold.eot');
    src: local('Product Sans Bold'), local('ProductSans-Bold'),
        url('ProductSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Bold.woff2') format('woff2'),
        url('ProductSans-Bold.woff') format('woff'),
        url('ProductSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-BlackItalic.eot');
    src: local('Product Sans Black Italic'), local('ProductSans-BlackItalic'),
        url('ProductSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-BlackItalic.woff2') format('woff2'),
        url('ProductSans-BlackItalic.woff') format('woff'),
        url('ProductSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Medium.eot');
    src: local('Product Sans Medium'), local('ProductSans-Medium'),
        url('ProductSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Medium.woff2') format('woff2'),
        url('ProductSans-Medium.woff') format('woff'),
        url('ProductSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-Regular.eot');
    src: local('Product Sans'), local('ProductSans-Regular'),
        url('ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-Regular.woff2') format('woff2'),
        url('ProductSans-Regular.woff') format('woff'),
        url('ProductSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('ProductSans-ThinItalic.eot');
    src: local('Product Sans Thin Italic'), local('ProductSans-ThinItalic'),
        url('ProductSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('ProductSans-ThinItalic.woff2') format('woff2'),
        url('ProductSans-ThinItalic.woff') format('woff'),
        url('ProductSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}


@white: #fff;@black: #000;@primary: #a5302e;@color-1: #333333;@color-2: fade(@color-1, 80%);@color-3: fade(@color-1, 50%);@color-4: #ededed;@color-5: #e5e5e5;@color-6: #b2b2b2;@color-7: fade(@primary, 60%);@color-8: #f3dede;@color-9: #f44336;@font-family-primary: Product Sans;@background-color-primary: @primary;@background-color-secondary: @color-4;@background-color-disabled: @color-7;@border-color: @color-5;@border-color-active: @color-6;@text-color-primary: @color-1;@text-color-secondary: @color-2;@text-color-disabled: @color-3;@btn-primary-disabled-color: @white;@btn-primary-disabled-bg: @color-6;@input-border-color: @color-5;@input-border-focus-color: @color-6;@placeholder-color: @color-6;@scrollbar-thumb-color: @color-6;@overlay-mask-color: rgba(0, 0, 0, 0.7);@btn-primary-background-color: @primary;@btn-primary-color: @white;@btn-secondary-background-color: @color-8;@btn-secondary-color: @primary;@btn-secondary-active-background-color: @btn-primary-background-color;@btn-secondary-active-color: @btn-primary-color;@hover-effect-card: fade(@background-color-secondary, 50%);@input-color-active: @primary;@input-color-hover: @black;@input-color-default: @color-3;@input-color-background: @white;@input-color-error: @color-9;@font-family: @font-family-primary;@primary-color: @background-color-primary;@body-background: @background-color-secondary;@text-color: @text-color-primary;@link-color: @background-color-primary;@heading-color: @text-color-primary;@tabs-hover-color: @link-color;@border-color-base: @border-color;@border-color-split: @border-color;@border-color-inverse: @white;