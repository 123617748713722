.change-password {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  &__field{
    margin-bottom: 15px ;
  }
  &__input{
    width: 100%;
  }
}

@white: #fff;@black: #000;@primary: #a5302e;@color-1: #333333;@color-2: fade(@color-1, 80%);@color-3: fade(@color-1, 50%);@color-4: #ededed;@color-5: #e5e5e5;@color-6: #b2b2b2;@color-7: fade(@primary, 60%);@color-8: #f3dede;@color-9: #f44336;@font-family-primary: Product Sans;@background-color-primary: @primary;@background-color-secondary: @color-4;@background-color-disabled: @color-7;@border-color: @color-5;@border-color-active: @color-6;@text-color-primary: @color-1;@text-color-secondary: @color-2;@text-color-disabled: @color-3;@btn-primary-disabled-color: @white;@btn-primary-disabled-bg: @color-6;@input-border-color: @color-5;@input-border-focus-color: @color-6;@placeholder-color: @color-6;@scrollbar-thumb-color: @color-6;@overlay-mask-color: rgba(0, 0, 0, 0.7);@btn-primary-background-color: @primary;@btn-primary-color: @white;@btn-secondary-background-color: @color-8;@btn-secondary-color: @primary;@btn-secondary-active-background-color: @btn-primary-background-color;@btn-secondary-active-color: @btn-primary-color;@hover-effect-card: fade(@background-color-secondary, 50%);@input-color-active: @primary;@input-color-hover: @black;@input-color-default: @color-3;@input-color-background: @white;@input-color-error: @color-9;@font-family: @font-family-primary;@primary-color: @background-color-primary;@body-background: @background-color-secondary;@text-color: @text-color-primary;@link-color: @background-color-primary;@heading-color: @text-color-primary;@tabs-hover-color: @link-color;@border-color-base: @border-color;@border-color-split: @border-color;@border-color-inverse: @white;