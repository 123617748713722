@import '../../theme.less';

.input-mask {
  border-radius: 4px;
  justify-content: center;
  cursor: text;

  .input-mask {
    &__label {
      position: absolute;
      z-index: 1;
      top: -8px;
      padding-left: 5px;
      padding-right: 5px;
      background-color: @input-color-background;
      margin-left: 8px;
      transform: translate(0, 20px) scale(1);
      &.MuiInputLabel-shrink { transform: translate(0, 1.5px) scale(0.75); }
    }
    
    &__input {
      margin-top: 0;
      padding: 9.5px 14px 9.5px 14px;
      border: 1px solid @input-color-default;
      border-radius: 4px;
      height: 40px;

      &:hover { border-color: @input-color-hover; }
      &.Mui-focused { border: 2px solid @input-color-active; }
      &.Mui-error { border-color: @input-color-error; }
      input { padding: 0; }
      &::before { content: none; }
      &::after { content: none; }
    }

    &__error {
      font-size: 12px;
      color: @input-color-error;
      margin: 4px 14px 0 14px;
    }

    &__prefix { padding-right: 4px; }

    &__postfix {
      height: 24px;
      width: 24px;
      svg {
        height: 24px;
        width: 24px;
        cursor: pointer;

        &:hover { fill: @input-color-active; }
      }
    }
  }
}
@white: #fff;@black: #000;@primary: #a5302e;@color-1: #333333;@color-2: fade(@color-1, 80%);@color-3: fade(@color-1, 50%);@color-4: #ededed;@color-5: #e5e5e5;@color-6: #b2b2b2;@color-7: fade(@primary, 60%);@color-8: #f3dede;@color-9: #f44336;@font-family-primary: Product Sans;@background-color-primary: @primary;@background-color-secondary: @color-4;@background-color-disabled: @color-7;@border-color: @color-5;@border-color-active: @color-6;@text-color-primary: @color-1;@text-color-secondary: @color-2;@text-color-disabled: @color-3;@btn-primary-disabled-color: @white;@btn-primary-disabled-bg: @color-6;@input-border-color: @color-5;@input-border-focus-color: @color-6;@placeholder-color: @color-6;@scrollbar-thumb-color: @color-6;@overlay-mask-color: rgba(0, 0, 0, 0.7);@btn-primary-background-color: @primary;@btn-primary-color: @white;@btn-secondary-background-color: @color-8;@btn-secondary-color: @primary;@btn-secondary-active-background-color: @btn-primary-background-color;@btn-secondary-active-color: @btn-primary-color;@hover-effect-card: fade(@background-color-secondary, 50%);@input-color-active: @primary;@input-color-hover: @black;@input-color-default: @color-3;@input-color-background: @white;@input-color-error: @color-9;@font-family: @font-family-primary;@primary-color: @background-color-primary;@body-background: @background-color-secondary;@text-color: @text-color-primary;@link-color: @background-color-primary;@heading-color: @text-color-primary;@tabs-hover-color: @link-color;@border-color-base: @border-color;@border-color-split: @border-color;@border-color-inverse: @white;